import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { forkJoin, lastValueFrom } from 'rxjs';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { RouterConstants } from '../constants/router-constants';
import { LoaderService } from '../loader/loader.service';
import { CepmService } from '../services/cepm.service';
import { LookupService } from '../services/lookup.service';
import { SplunkLogsService } from '../services/splunkLogs.service';
import { ReportRendererComponent } from './report-renderer/report-renderer.component';

@Component({
  selector: 'app-report-issues',
  templateUrl: './report-issues.component.html',
  styleUrls: ['./report-issues.component.css']
})
export class ReportIssuesComponent implements OnInit {
  @ViewChild('allIssueGrid') allIssueGrid: AgGridAngular;
  incidentId: any;
  @Output() clickReportIssues = new EventEmitter<any>();
  issueForm: UntypedFormGroup;
  supportedFileExtensions: any = 'JPG, JPEG, PNG, PDF, XLS, XLSX, PPT, PPTX, DOC, DOCX,';
  categories: any = ['Report Wrong / Missing Data', 'Report Broken Link / Application / Access Issues'];
  showBrokenLink: any = false;
  showDataMeasureName: any = false;
  selectedFiles: any = [];
  reportTabs: any = ['Report New Issue', 'My Issues', 'All Issues'];
  isInvalidFileFormat: any = false;
  selectedReportTabIndex: any = -1;
  modalRefBox: any;
  showIncident = false;
  incidentComments: any = [];
  loggedInUser: any = '';
  dataMeasureArr: any = [];
  incidentIdArr: any = [];
  searchedText: any = '';
  btnText: any;
  userType: any;

  public frameworkComponents;
  public context;

  // My Issues
  myIssuesList: any = {
    rowData: [],
    columnDefs: [
      {
        headerName: 'Case Category',
        headerTooltip: 'Case Category',
        field: 'summary',
        tooltipValueGetter: (params) => params.value,
        flex: 1
      },
      {
        headerName: 'Description',
        headerTooltip: 'Description',
        field: 'description',
        tooltipValueGetter: (params) => params.value,
        width: 400,
        flex: 1
      },
      {
        headerName: 'Case Number',
        headerTooltip: 'Case Number',
        field: 'case_no',
        tooltipValueGetter: (params) => params.value,
        flex: 1,
        cellRenderer: 'reportRenderer'
      },
      {
        headerName: 'Created On',
        field: 'created_on',
        headerTooltip: 'Created On',
        tooltipValueGetter: (params) => params.value,
        type: 'dateColumn'
      },
      {
        headerName: 'Action',
        headerTooltip: 'Action',
        tooltipValueGetter: (params) => params.value,
        cellRenderer: 'reportRenderer',
        width: 150
      }
    ]
  };


  // issueList
  allIssueList: any = {
    rowData: [],
    columnDefs: [
      {
        headerName: 'Case Category',
        headerTooltip: 'Case Category',
        field: 'summary',
        tooltipValueGetter: (params) => params.value,
        flex: 1,
        width: 350
      },
      {
        headerName: 'Description',
        headerTooltip: 'Description',
        field: 'description',
        tooltipValueGetter: (params) => params.value,
        width: 400,
        flex: 1
      },
      {
        headerName: 'Case Number',
        headerTooltip: 'Case Number',
        field: 'case_no',
        tooltipValueGetter: (params) => params.value,
        flex: 1,
        cellRenderer: 'reportRenderer',
        width: 80
      },
      {
        headerName: 'Created On',
        field: 'created_on',
        headerTooltip: 'Created On',
        tooltipValueGetter: (params) => params.value,
        flex: 1,
        width: 150,
        type: 'dateColumn'
      },
      {
        headerName: 'Created By',
        field: 'created_by',
        headerTooltip: 'Created By',
        tooltipValueGetter: (params) => params.value,
        flex: 1,
        width: 100
      },
      {
        headerName: 'Supplier Name',
        field: 'supplier_name',
        headerTooltip: 'Supplier Name',
        tooltipValueGetter: (params) => params.value,
        flex: 1,
        width: 150
      },
      {
        headerName: 'Action',
        headerTooltip: 'Action',
        tooltipValueGetter: (params) => params.value,
        cellRenderer: 'reportRenderer',
        width: 150,
        filter: false
      }
    ]
  };

  gridOptions: any = {
    tooltipShowDelay: 0
  };

  defaultColDef: any = {
    sortable: true,
    resizable: true,
    editable: false,
    enableRowGroup: false,
    floatingFilter: true,
    filter: 'agTextColumnFilter'
  }

  columnTypes: any = {
    dateColumn: {
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const searchedDate = new Date(
            this.datePipe.transform(new Date(filterLocalDateAtMidnight), 'MM/dd/yyyy HH:mm:ss')
          ).setHours(0, 0, 0, 0);

          if (!cellValue) {
            return 0;
          }

          const cellDate = new Date(
            this.datePipe.transform(new Date(cellValue), 'MM/dd/yyyy HH:mm:ss')
          ).setHours(0, 0, 0, 0);

          if (cellDate < searchedDate) {
            return -1;
          }
          else if (cellDate > searchedDate) {
            return 1;
          }
          return 0;
        }
      },
      comparator: this.dateComparatorSort
    }
  };

  constructor(
    private modalService: NgbModal,
    private loaderService: LoaderService,
    private cepmService: CepmService,
    private routerConstants: RouterConstants,
    private datePipe: DatePipe,
    private fb: UntypedFormBuilder,
    private lookupService: LookupService,
    private ref: ChangeDetectorRef,
    private splunkLogService: SplunkLogsService
  ) {
    ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 200);
    this.context = { componentParent: this };
    this.frameworkComponents = {
      reportRenderer: ReportRendererComponent
    };
  }

  ngOnInit(): void {
    this.loggedInUser = this.routerConstants.getLoggedInUser();
    this.issueForm = this.fb.group({
      category: ['', [Validators.required]],
      dataMeasureName: [''],
      brokenLinkName: [''],
      additionalComments: ['', [Validators.required]],
      fileName: ['']
    });
    this.userType = this.routerConstants.getUserType();
    if ((this.userType !== 'scw_admin_res') && (this.userType !== 'scw_supplier_user_res')) {
      this.reportTabs.splice(-1);
    }
    if (this.userType === 'scw_supplier_user_res') {
      for (const i of this.allIssueList.columnDefs) {
        if (i.headerName === 'Action') {
          i.headerName = 'View Updates';
          i.headerTooltip = 'View Updates';
        }
      }
      for (const i of this.myIssuesList.columnDefs) {
        if (i.headerName === 'Action') {
          i.headerName = 'View Updates';
          i.headerTooltip = 'View Updates';
        }
      }
    }
    this.getLookupValues();
  }

  async getLookupValues() {
    this.loaderService.openLoader();

    await lastValueFrom(forkJoin([
      this.lookupService.getDropdownLookup('SCW_DATA_MEASURE'),
      this.lookupService.getDropdownLookup('SCW_REPORT_ISSUES_BTN')
    ]))
      .then((respData: any) => {
        this.loaderService.closeLoader();
        if (respData && respData.length > 0) {
          this.dataMeasureArr = respData[0].lookupArray;
          this.btnText = respData[1].lookupArray[0].description;
        }
      })
      .catch((err: any) => {
        this.loaderService.closeLoader();
        this.openConfirmationPopup('Error', err.error.errorMsg, false);
      });
    this.onChangeSelectedTab(0);
  }

  onChangeSelectedTab(selectedTabIndex: any) {
    this.selectedReportTabIndex = selectedTabIndex;
    this.showBrokenLink = false;
    this.showDataMeasureName = false;
    this.issueForm.reset();
    this.incidentComments = [];
    this.searchedText = '';
    if (selectedTabIndex === 1) {
      this.getMyIssues();
    } else if (selectedTabIndex === 2) {
      this.getAllIssues();
    }
  }

  getMyIssues() {
    if (this.myIssuesList.rowData.length === 0) {
      this.loaderService.openLoader();
      this.splunkLogService.viewAllIssues()
        .subscribe({
          next: (respData: any) => {
            this.loaderService.closeLoader();
            this.myIssuesList.rowData = respData.myIssue;
          },
          error: (err: any) => {
            this.loaderService.closeLoader();
            this.openConfirmationPopup('Error', err.error.errorMsg, false);
          }
        });
    }
  }

  getAllIssues() {
    if (this.allIssueList.rowData.length === 0) {
      this.loaderService.openLoader();
      this.splunkLogService.viewAllIssues()
        .subscribe({
          next: (respData: any) => {
            this.loaderService.closeLoader();
            this.allIssueList.rowData = respData.allIssue;
            this.hideSupplierName();
          },
          error: (err: any) => {
            this.loaderService.closeLoader();
            this.openConfirmationPopup('Error', err.error.errorMsg, false);
          }
        });
    }
  }

  hideSupplierName() {
    if (this.userType === 'scw_supplier_user_res') {
      this.allIssueGrid.api.setColumnsVisible(['supplier_name'], false);
    }
    else {
      this.allIssueGrid.api.setColumnsVisible(['supplier_name'], true);
    }
    this.allIssueGrid.api.getColumns().forEach((col: any) => {
      col.colDef.suppressColumnsToolPanel = col.visible ? false : true;
    });
    this.allIssueGrid.api.refreshCells({ force: true });
  }

  async getAllIncidentIds() {
    this.incidentIdArr = [];
    this.loaderService.openLoader();
    const respData: any = await lastValueFrom(this.cepmService.getAllIncidents())
      .catch((err: any) => {
        this.loaderService.closeLoader();
        this.openConfirmationPopup('Error', err.error.errorMsg, false);
      });
    this.loaderService.closeLoader();
    if (respData && Object.keys(respData).length > 0 && respData.incidentNumber.length > 0) {
      this.incidentIdArr = respData.incidentNumber;
    }
    else {
      this.incidentIdArr.push('No active incidents available');
    }
  }

  uploadFile(files: any) {
    this.checkFileExtension(files);
    if (this.isInvalidFileFormat) {
      return;
    }
    else {
      this.selectedFiles = files;
      this.issueForm.get('fileName').setValue(files[0].name);
    }
  }

  onFileDropped(event: any) {
    this.uploadFile(event);
  }

  onClickDeleteFile() {
    this.selectedFiles = [];
    this.isInvalidFileFormat = false;
    this.issueForm.get('fileName').reset();
  }

  checkFileExtension(files: any): any {
    this.isInvalidFileFormat = false;
    for (const file of files) {
      const foundIndex = this.getLastIndexOf(Object.assign([], file.name), '.');
      const splitAt = index => x => [x.slice(0, index), x.slice(index)];
      const fileExt = splitAt(foundIndex)(file.name)[1];
      if (this.supportedFileExtensions.indexOf(fileExt.split('.')[1].toUpperCase()) === -1) {
        this.isInvalidFileFormat = true;
        break;
      }
    }
  }

  getLastIndexOf(array, key) {
    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i] === key) {
        return i;
      }
    }
    return -1;
  }

  formatBytes(bytes: any, decimals?: any) {
    if (bytes) {
      if (bytes === 0) {
        return '0 Bytes';
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals || 2;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    else {
      return '';
    }
  }

  getFileName(name: any) {
    return name;
  }

  onSelectCategory(event: any) {
    this.showDataMeasureName = false;
    this.issueForm.get('dataMeasureName').clearValidators();
    this.issueForm.get('dataMeasureName').reset();
    this.showBrokenLink = false;
    this.issueForm.get('brokenLinkName').clearValidators();
    this.issueForm.get('brokenLinkName').reset();
    if (event.value === 'Report Wrong / Missing Data') {
      this.issueForm.get('dataMeasureName').setValidators([Validators.required]);
      this.showDataMeasureName = true;
    }
    if (event.value === 'Report Broken Link / Application / Access Issues') {
      this.issueForm.get('brokenLinkName').setValidators([Validators.required, Validators.maxLength(149)]);
      this.showBrokenLink = true;
    }
    this.issueForm.get('dataMeasureName').updateValueAndValidity();
    this.issueForm.get('brokenLinkName').updateValueAndValidity();
  }

  onClickSubmit() {
    if (this.issueForm.invalid) {
      return;
    }
    else {
      const shortDesc = this.issueForm.get('category').value === 'Report Wrong / Missing Data' ?
        `Report Wrong / Missing Data - ${this.issueForm.get('dataMeasureName').value}`
        : `Broken Link Name - ${this.issueForm.get('brokenLinkName').value}`;

      const requestObj = {
        caller_id: this.loggedInUser.role.value === 'scw_supplier_user_res' ? 'scw-external.gen' : this.loggedInUser.userId,
        description: `Issue Description - ${this.issueForm.get('additionalComments').value}`,
        short_description: shortDesc,
        u_business_service: 'Supplier Sourcing',
        contact_type: 'web',
        u_impacted_function: this.loggedInUser.environment === 'prod' ? 'sc-src-scw' : 'sc-scrm bo'
      };

      const supplierName = this.loggedInUser.role.value === 'scw_supplier_user_res' ? this.loggedInUser.employeeCompany : '';
      this.loaderService.openLoader();
      this.cepmService.openCase(requestObj, supplierName)
        .subscribe({
          next: async (respData: any) => {
            this.loaderService.closeLoader();
            if (respData && Object.keys(respData).length > 0) {
              if (respData.number) {
                const cmfmMsg = `Incident ${respData.number} has been opened for "${this.loggedInUser.fullName}","${this.loggedInUser.email}".`;
                if (this.selectedFiles.length > 0) {
                  await this.uploadFileToCase(cmfmMsg, respData.systemId);
                }
                else {
                  if (respData.status === 'S') {
                    this.openConfirmationPopup('Confirmation', `${cmfmMsg}`, false);
                    this.onClose('close');
                  }
                  else if (respData.status === 'E' && respData.errorMsg) {
                    this.openConfirmationPopup('Error', `${cmfmMsg} ${respData.errorMsg}`, false);
                  }
                  else {
                    this.openConfirmationPopup('Error', `${cmfmMsg}. Error while saving incident details in database...`, false);
                  }
                }
              }
              else {
                this.openConfirmationPopup('Error', 'Error while creating case.', false);
              }
            }
          },
          error: (err: any) => {
            this.loaderService.closeLoader();
            this.openConfirmationPopup('Error', err.error.errorMsg, false);
          }
        });
    }
  }

  async uploadFileToCase(cmfmMsg: any, systemId: any) {
    const formData = new FormData();
    formData.append('file', this.selectedFiles[0]);
    this.loaderService.openLoader();
    await lastValueFrom(this.cepmService.uploadFileToCase(formData, systemId, this.selectedFiles[0].name.replace(/\s/g, '')))
      .then((respData: any) => {
        this.loaderService.closeLoader();
        if (respData && Object.keys(respData).length > 0 && respData.status === 'S') {
          this.openConfirmationPopup('Confirmation', cmfmMsg, false);
          this.onClose('close');
        }
        else if (respData && Object.keys(respData).length > 0 && respData.status === 'E' && respData.errorMsg) {
          this.openConfirmationPopup('Error', `${cmfmMsg} ${respData.errorMsg}`, false);
        }
        else {
          this.openConfirmationPopup('Error', `${cmfmMsg}, Error while uploading file... `, false);
        }
      })
      .catch((err: any) => {
        this.loaderService.closeLoader();
        this.openConfirmationPopup('Error', err.error.errorMsg, false);
      });
  }

  onSelectIncidentId(event: any) {
    this.incidentId = event.case_no;
    this.incidentComments = [];
    this.loaderService.openLoader();
    this.cepmService.getCaseComments(event.case_no)
      .subscribe({
        next: (respData: any) => {
          this.loaderService.closeLoader();
          if (respData && respData.comments) {
            this.showIncident = true;
            const comments = respData.comments.replace(/"/g, '').split('\n\n');
            comments.forEach((comm: any) => {
              if (comm) {
                const commType = comm.includes('(Additional comments)') ? 'additional comment' : 'work notes';
                const timeNcommentBy = comm.includes('(Work notes (Agent visible))') ? comm.split('(Work notes (Agent visible))')[0]
                  : comm.split('(Additional comments)')[0];
                this.incidentComments.push({
                  comment: comm.includes('(Work notes (Agent visible))') ? comm.split('(Work notes (Agent visible))')[1].replace(/\r/g, '')
                    : comm.split('(Additional comments)')[1].replace(/\r/g, ''),
                  commentType: commType,
                  commentedBy: timeNcommentBy.split(' - ')[1],
                  commentTime: timeNcommentBy.split(' - ')[0]
                });
              }
            });
          }
          else {
            this.openConfirmationPopup('Confirmation', 'Comments Not Available For This Incident', false);
          }
        },
        error: (err: any) => {
          this.loaderService.closeLoader();
          this.openConfirmationPopup('Error', err.error.errorMsg, false);
        }
      });
  }

  dateComparatorSort(date1: any, date2: any) {
    return +new Date(date1) - +new Date(date2);
  }

  onClose(val: any) {
    this.clickReportIssues.emit(val);
  }

  onCloseIncident() {
    this.showIncident = false;
  }

  openConfirmationPopup(title: any, msg: any, flag: any) {
    this.modalRefBox = this.modalService.open(ConfirmationComponent, {
      centered: true,
      windowClass: 'alertModalWindowClass',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalRefBox.componentInstance.title = title;
    this.modalRefBox.componentInstance.message = msg;
    this.modalRefBox.componentInstance.showCancelButton = flag;
  }
}
