import {APP_INITIALIZER, NgModule} from '@angular/core';
import {CallbackComponent} from './pages/callback.component';
import { AuthService } from '../auth/auth.service';

export function init_auth(authService: AuthService) {
  return () => authService.initAuth();
}

@NgModule({
  declarations: [CallbackComponent],
  imports: [
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: init_auth,
      deps: [AuthService],
      multi: true
    }
  ]
})
export class CallbackModule { }
