import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoDetailsComponent } from './info-details.component';
import { QuillModule } from 'ngx-quill';
import { MaterialModule } from '../material.module';


@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        QuillModule.forRoot()
    ],
    declarations: [
        InfoDetailsComponent
    ],
    providers: [
    ],
    exports: [
        InfoDetailsComponent
    ]
})

export class InfoModule { }
