import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SupplierHomeService {
    notificationCount = 0;
    public apiUrl:any;
    constructor(
        private http: HttpClient
    ) { 
        this.apiUrl = window['__env'].apiUrl;
    }

    qualityHomeDummyData =
        {
            qualityMetrics: [
                {
                    quarter: 'Q2FY22',
                    qp: 1,
                    qpCommodity: 'ASICS',
                    qi: 1,
                    qiCommodity: '',
                    qr: 2,
                    qrCommodity: 'MPU'
                }
            ]
        };

    fulfillmentDummyData = [
        {
            quarter: 'Q4FY22',
            commodity: 'COMMUNICATIONS',
            cto: 0.000000,
            agility: null,
            ctr: 92.000000
        },
        {
            quarter: 'Q4FY22',
            commodity: 'ASICS',
            cto: 9.310000,
            agility: 0.000000,
            ctr: 98.000000
        },
        {
            quarter: 'Q4FY22',
            commodity: 'MPU',
            cto: 75.120000,
            agility: 0.000000,
            ctr: 95.280000
        },
        {
            quarter: 'Q4FY22',
            commodity: 'MEMORY',
            cto: 79.190000,
            agility: 8.390000,
            ctr: 93.650000
        },
        {
            quarter: 'Q4FY22',
            commodity: 'PLD',
            cto: 92.330000,
            agility: 58.720000,
            ctr: 89.000000
        }
    ];

    getActiveWizardsForSupplier(supplierName: any, commodity: any, flag: any) {
        return this.http.get(`${this.apiUrl}/homeService/getActiveWizards?supplierName=${encodeURIComponent(supplierName)}&commodity=${encodeURIComponent(commodity)}&viewType=${flag}`);
    }

    getSupplierData(supplierName: any, commodity: any, flag: any) {
        return this.http.get(`${this.apiUrl}/homeService/getHomeData` +
            `?supplierName=${encodeURIComponent(supplierName)}&commodity=${encodeURIComponent(commodity)}&viewType=${flag}`);
    }

    // Get Pending Scope RFQ's List
    getPendingScopeRfqData(requestObj: any) {
        return this.http.post(`${this.apiUrl}/supplierService/getSupplierReqCountDetails`, requestObj);
    }

    getSbrScoreCardData(flag: any, supplierName: any, commodities: any) {
        return this.http.get(`${this.apiUrl}/sbrService/fetchSbrScoreCardData?flag=${flag}&supplier=${encodeURIComponent(supplierName)}&commodity=${encodeURIComponent(commodities)}`);
    }

    getResiliencyScoreCardData(flag: any, supplierName: any, commodities: any) {
        return this.http.get(`${this.apiUrl}/sbrService/fetchResiliencyScoresData?flag=${flag}&supplier=${encodeURIComponent(supplierName)}&commodity=${encodeURIComponent(commodities)}`);
    }

    getNewsInfo(supplierName: any, emailId: any) {
        return this.http.get(`${this.apiUrl}/homeService/getNewsInfo?supplierName=${encodeURIComponent(supplierName)}&emailId=${emailId}`);
    }

    getQualityMetrics(requestObj: any) {
        return this.http.post(`${this.apiUrl}/homeService/getHomeQualityMetricsData`, requestObj);
    }

    getFulfillmentMetrics(requestObj: any) {
        return this.http.post(`${this.apiUrl}/fulfillmentService/fetchFulfillmentTableDetails`, requestObj);
    }

    getPendingRFQsData(requestObj: any) {
        return this.http.post(`${this.apiUrl}/baaoService/fetchQuoteDetails`, requestObj);
    }

    getQuoteSummaryData(requestObj: any) {
        return this.http.post(`${this.apiUrl}/baaoService/fetchQuoteSummary`, requestObj);
    }

    getSucmMyViewData(requestObj: any) {
        return this.http.post(`${this.apiUrl}/fulfillmentService/fetchTableDetailsForSucm`, requestObj);
    }

    getSucmOverallViewData(requestObj: any) {
        return this.http.post(`${this.apiUrl}/fulfillmentService/fetchOverallView`, requestObj);
    }

    getSucmSearchBySupplier(requestObj: any, isOverall: any) {
        return this.http.post(`${this.apiUrl}/fulfillmentService/fetchTableDetailsForSucmBySupplier?isOverall=${isOverall}`, requestObj);
    }

    increaseNotificationCount() {
        this.notificationCount++;
    }
    getNotificationCount() {
        return this.notificationCount;
    }
    resetNotificationCount() {
        this.notificationCount = 0;
    }
    getAllWatchlists() {
        return this.http.get(`${this.apiUrl}/watchListService/getAllWatchLists`);
    }

    getWatchlistData(watchlistId: any) {
        return this.http.get(`${this.apiUrl}/watchListService/getAllWatchListData?watchListId=${watchlistId}`);
    }

    createUpdateWatchlist(requestObj: any) {
        return this.http.post(`${this.apiUrl}/watchListService/createUpdateWatchList`, requestObj);
    }

    deleteWatchlist(watchlistId: any) {
        return this.http.post(`${this.apiUrl}/watchListService/deleteWatchList?watchListId=${watchlistId}`, {});
    }
    fetchFHR(supplierName) {
        return this.http.get(`${this.apiUrl}/fhrService/getAllFhrListData?supplier=${encodeURIComponent(supplierName)}`);
    }

    getSerMetricsScore(supplier: any) {
        return this.http.get(`${this.apiUrl}/supplierService/viewSerMetricsData?supplierName=${encodeURIComponent(supplier)}`);
    }

    createUpdateCustomFilter(requestObj: any) {
        return this.http.post(`${this.apiUrl}/fulfillmentService/createUpdateCustomFilter`, requestObj);
    }

    fetchCustomFilter(viewFlag: any) {
        return this.http.get(`${this.apiUrl}/fulfillmentService/fetchCustomFilter?flag=${viewFlag}`);
    }

    deleteCustomFilter(filterId: any) {
        return this.http.post(`${this.apiUrl}/fulfillmentService/deleteCustomFilter?filterId=${filterId}`, {});
    }

    downloadSucmViewData(requestObj: any) {
        return this.http.post(`${this.apiUrl}/fulfillmentService/downloadM23Data`, requestObj,
            {
                responseType: "blob",
                headers: new HttpHeaders().append("Content-Type", "application/json")
            });
    }

    createUpdateSbrData(requestObj: any) {
        return this.http.post(`${this.apiUrl}/sbrService/createUpdateSbrScoreCard`, requestObj);
    }

    deleteSbrScore(requestObj: any) {
        return this.http.post(`${this.apiUrl}/sbrService/deleteSbrScoreCardData`, requestObj);
    }

    saveSupplierNotes(requestObj: any) {
        return this.http.post(`${this.apiUrl}/supplierService/saveSupplierNotes`, requestObj);
    }

    getSupplierNote(supplierName: any) {
        return this.http.get(`${this.apiUrl}/supplierService/getSupplierNotes?supplier=${encodeURIComponent(supplierName)}`);
    }

    downloadAttachments(messageId: any, attachmentId: any) {
        return this.http.get(`${this.apiUrl}/messageService/downloadFile?messageId=${messageId}&attachmentId=${attachmentId}`, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }

    getNewSupplierHomeActionsData(status: any, supplierName: any) {
        return this.http.get(`${this.apiUrl}/supplierService/surveys?status=${encodeURIComponent(status)}&supplierName=${encodeURIComponent(supplierName)}`);
    }

    getNewSupplierHomePriorityMessages(supplierName: any) {
        return this.http.get(`${this.apiUrl}/supplierService/` + supplierName + `/messages`);
    }
    // getTeamCard(supplierName:any){
    //     return this.http.get(`${this.apiUrl}/supplierService/` + supplierName + `/teamCard`);
    // }
    updateNotification(supplierName: any, messageId:any,requestObj: any) {
        return this.http.put(`${this.apiUrl}/supplierService/` + supplierName + `/message/` + messageId, requestObj);
    }

    getTeamCard(supplierName: any) {
        return this.http.get(`${this.apiUrl}/supplierService/` + supplierName + `/teamCard`);
    }

    getPerformanceScoreCardData(supplierName: any, metricname: any) {
        return this.http.get(`${this.apiUrl}/supplierService/` + supplierName + `/performanceCard/` + metricname);
    }
}

