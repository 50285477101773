import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';
import { EnvService } from '../services/env.service';
import { SharedService } from '../shared-service/shared.service';
import { EncrDecrService } from '../services/encrypt-decrypt.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  state = new BehaviorSubject<string>('');
  state$ = this.state.asObservable();
  isRefreshInProgress = false;

  constructor(
    private location: Location,
    private oauthService: OAuthService,
    private env: EnvService,
    private encDecSrvice: EncrDecrService,
    private sharedService: SharedService,
    private router: Router

  ) {
    console.log("Entered in Auth Service")
    if (!this.env.ssoUrl) {
      console.error('SSO URL is blank.', this.env);
    }

    // setup oauth
    this.oauthService.configure(this.env.oauthConfig());
    //this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.events.subscribe((event) => {  
      console.log("oauth event---",event);    
      if (event.type === 'token_received') {
        console.log(
          'Access token received, and state = ',
          this.oauthService.state
        );
        console.log(this.oauthService.getAccessToken())
        window.localStorage.setItem('adata', this.encDecSrvice.set(this.oauthService.getAccessToken()));
        window.localStorage.setItem('rtoken', this.encDecSrvice.set(this.oauthService.getRefreshToken()));
        if(this.oauthService.state != '/'){
          this.state.next(this.oauthService.state);
        }
    }else if(event.type === "token_refresh_error"){
      window.localStorage.clear();
      this.router.navigate(['/logout']);
    }
    }, (error) => {
      //this.sharedService.sendClickEvent(false);
      //this.oauthService.logOut();
      window.localStorage.clear();
      this.router.navigate(['/logout']);
    });;
  }

  public isAuthenticated(): boolean {
    console.log(this.oauthService.hasValidAccessToken())
    return this.oauthService.hasValidAccessToken() ? true : false;
  }

  async login(): Promise<boolean> {
    return this.oauthService.loadDiscoveryDocumentAndLogin().then(
      (isLoggedIn) => {
        if (isLoggedIn) {
          return true;
        } else {
          this._initCodeFlow();
          return false;
        }
      },
      (error) => {
        this._initCodeFlow();
        return false;
      }
    );
  }

  public logout(redirectTo = '/logout'): void {
    this.oauthService.logOut();
  }

  private _initCodeFlow(): void {
    console.log('init code flow');
    const state = this.location.path() === '' ? '/' : this.location.path();
    this.oauthService.initCodeFlow(state);
  }

  initAuth(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Skip auth for logout route.
      if (window.location.pathname === '/logout') {
        return resolve();
      }

      if (this.isAuthenticated()) {
        console.log('Found access token');
        this.oauthService.loadDiscoveryDocumentAndTryLogin();
        return resolve();
      } else {
        console.log('No access token');
        return this.login()
          .then((isLoggedIn) => {
            if (isLoggedIn) {
              return this._getUserInfo(resolve, reject);
            }
          })
          .catch((error: any) => {
            window.document.body.classList.add('failed');
            console.error('Jumping to SSO failed.');
            return reject(error || 'server error');
          });
      }
    });
  }

  _getUserInfo(resolve, reject) {
  return resolve()
  } 
}
