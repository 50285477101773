import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { MaterialModule } from '../material.module';
import { PipesModule } from '../pipes/pipes.module';
import { ReportIssuesComponent } from './report-issues.component';
import { AgGridModule } from 'ag-grid-angular';
import { ReportRendererComponent } from './report-renderer/report-renderer.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        AgGridModule,
        QuillModule.forRoot(),
    ],
    declarations: [
        ReportIssuesComponent,
        ReportRendererComponent
    ],
    exports: [
        ReportIssuesComponent
    ]
})

export class ReportIssuesModule { }
