import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { SharedService } from '../shared-service/shared.service';

@Injectable()
export class AuthGuard implements CanLoad, CanDeactivate<any> {
    isValid = false;
    constructor(
        private router: Router,
        private sharedService: SharedService
    ) { }

    canDeactivate(component: any,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot) {
        this.isValid = window.localStorage.length === 0 ? false : true;
        if ((currentState.url === '/logout' || currentState.url === '/no-cepm-role'
            || currentState.url === '/supplier-out-of-scope') && !this.isValid) {
            return false;
        }
        else {
            return true;
        }
    }

    checkTokenExpiration() {
        const currentUrl: any = window.location.href;

        // checking whether your token has expired
        if (window.localStorage.length === 0 && currentUrl && currentUrl.split('#')[1] && currentUrl.split('#')[1] !== '/') {
            return false;
        }
        else {
            return true;
        }
    }

    canLoad() {
        this.isValid = this.checkTokenExpiration();
        if (!this.isValid) {
            this.sharedService.sendClickEvent(false);
        }
        else {
            return true;
        }
    }
}
