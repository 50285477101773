import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService implements OnDestroy {
  private clickSubject = new Subject<boolean>();
  private isRedirectedFromAnotherComp = new Subject<string>();
  private isNonBaaoSuppAdded = new Subject<boolean>();
  private isQuickTourClicked = new Subject<string>();
  private filterPanelValue = new Subject<string>();
  private showSupplierNote = new Subject<any>();
  private logOutSubject = new Subject<boolean>();
  private tabClick = new Subject<string>();
  viewType: any = '';
  publishObj: any = {};
  itemsPerPage: any = '';
  wizards: any = [];

  constructor() {
    this.start();
  }

  sendClickEvent(val: any) {
    this.clickSubject.next(val);
  }

  getClickEvent(): Observable<boolean> {
    return this.clickSubject.asObservable();
  }

  setPublishViewType(viewType: any) {
    this.viewType = viewType;
  }

  getPublishViewType() {
    return this.viewType;
  }

  setPublishObject(publishObj: any) {
    this.publishObj = publishObj;
  }

  getPublishObject() {
    return this.publishObj;
  }

  setItemsPerPage(itemsPerPage: any) {
    this.itemsPerPage = itemsPerPage;
  }

  getItemsPerPage() {
    return this.itemsPerPage;
  }

  setRedirectionUrl(val: any) {
    this.isRedirectedFromAnotherComp.next(val);
  }

  getRedirectionUrl(): Observable<string> {
    return this.isRedirectedFromAnotherComp.asObservable();
  }

  setWizardsToPreview(wizards: any) {
    this.wizards = wizards;
  }

  getWizardsToPreview() {
    return this.wizards;
  }

  setNonBaaoSupp(val: any) {
    this.isNonBaaoSuppAdded.next(val);
  }

  getNonBaaoSupp(): Observable<boolean> {
    return this.isNonBaaoSuppAdded.asObservable();
  }

  setQuickTour(val: any) {
    this.isQuickTourClicked.next(val);
  }

  getQuickTour(): Observable<string> {
    return this.isQuickTourClicked.asObservable();
  }

  setFilterPanelValue(val: any) {
    this.filterPanelValue.next(val);
  }

  getFilterPanelValue(): Observable<string> {
    return this.filterPanelValue.asObservable();
  }

  setSupplierNote(val: any) {
    this.showSupplierNote.next(val);
  }

  getSupplierNote(): Observable<any> {
    return this.showSupplierNote.asObservable();
  }

  logOut() {
    this.logOutSubject.next(true);
  }

  getLogOut(): Observable<boolean> {
    return this.logOutSubject.asObservable();
  }

  start(): void {
    window.addEventListener("storage", this.storageEventListener.bind(this));
  }

  // Logout only when key is 'logout-event'
  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event?.key && event.key == 'logout-event') {
        this.logOut()
      }
    }
  }

  // Handle active listeners when onDestroy 
  private stop(): void {
    window.removeEventListener("storage", this.storageEventListener.bind(this));
  }

  ngOnDestroy() {
    this.stop();
  }

  sendTabClickEvent(val: any) {
    this.tabClick.next(val);
  }

  getTabClickEvent(): Observable<string> {
    return this.tabClick.asObservable();
  }
}
