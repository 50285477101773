import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-simple-cell-renderer',
  templateUrl: './simple-cell-renderer.component.html',
  styleUrls: ['./simple-cell-renderer.component.css']
})
export class SimpleCellRendererComponent implements ICellRendererAngularComp {
  public params: any;
  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  onExpandCollapseClick(event: MouseEvent): void {
    this.params.api.getRowNode(this.params.node.id).setExpanded(!this.params.node.expanded);
  }

  showPublishStatus() {
    if (Number(this.params.data.publishedSupplierCount) === 0) {
      return 'Pending Publish';
    }
    else {
      if (Number(this.params.data.publishedSupplierCount) === Number(this.params.data.impactedSupplierCount)) {
        return 'All Published';
      }
      if (Number(this.params.data.publishedSupplierCount) < Number(this.params.data.impactedSupplierCount)) {
        return 'Partially Published';
      }
    }
  }

  showStatusColor() {
    if (this.showPublishStatus() === 'Pending Publish') {
      return 'red';
    }
    else if (this.showPublishStatus() === 'All Published') {
      return 'green';
    }
    else if (this.showPublishStatus() === 'Partially Published') {
      return 'blue';
    }
  }

  showColorForPublishedSupplierCount() {
    if (this.showPublishStatus() === 'All Published') {
      return 'green';
    }
    else {
      return 'red';
    }
  }
}
