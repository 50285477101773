import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class SplunkLogsService {
    public apiUrl:any;
    constructor(private http: HttpClient) {
        this.apiUrl = window['__env'].apiUrl;
    }

    // send additional logs to server
    additionalLogs(requestObj: any) {
        return this.http.post(`${this.apiUrl}/loggerService/addLogs`, requestObj);
    }

    supplierSmmaryMailLogs(requestObj: any) {
      return this.http.get(`${this.apiUrl}/loggerService/addSuppSummaryLogs?viewFlag='+requestObj.viewFlag +'&supplierName='+requestObj.supplierName +'&emailId='+requestObj.emailId+ '&userId='+requestObj.userId`);
    }
    // report issues changes
    viewAllIssues() {
        return this.http.get(`${this.apiUrl}/issueReporterService/viewAllReportIssues`);
    }
}
