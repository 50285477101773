<div class="modal fade scoreInfoPopup" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="margin-top: 2% !important;">
            <div class="modal-header">
                <div class="row">
                    <div class="col-11">
                        <h1 class="modal-title">Information</h1>
                    </div>
                    <div class="col-1">
                        <button mat-icon-button style="float: right;" (click)="onClickClose('close')">
                            <span class="icon-close" style="float: right;">
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body" style="text-align: initial;">
                <div class="row">
                    <quill-view-html [content]="infoData?infoData.description:''">
                    </quill-view-html>
                </div>
            </div>
        </div>
    </div>
</div>