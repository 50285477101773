import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info-details',
  templateUrl: './info-details.component.html',
  styleUrls: ['./info-details.component.css']
})
export class InfoDetailsComponent {
  @Input() infoData: any;
  @Output() clickClose = new EventEmitter<string>();

  constructor(
    private ref: ChangeDetectorRef
  ) {
    ref.detach();
    setInterval(() => { this.ref.detectChanges(); }, 200);
  }

  onClickClose(val: any) {
    this.clickClose.emit(val);
  }
}
