import { Subject } from 'rxjs';

export class RouterConstants {
    private loggedInUser;
    private userType;
    isPageReloaded = new Subject<boolean>();

    public getLoggedInUser() {
        return this.loggedInUser;
    }

    public setLoggedInUser(val: any) {
        this.loggedInUser = val;
    }

    public getUserType() {
        return this.userType;
    }

    public setUserType(val: any) {
        this.userType = val;
    }

    public getIsPageReloaded() {
        return this.isPageReloaded.asObservable();
    }

    public setIsPageReloaded(val: any) {
        this.isPageReloaded.next(val);
    }
}
