<div class="modal fade confirmationPopup" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="margin-top: 2% !important;">
            <div class="modal-header">
                <div class="row">
                    <div class="col-11">
                        <h1 class="modal-title">{{title}}</h1>
                    </div>
                    <div class="col-1">
                        <button mat-mini-fab class="white infoIcon" matTooltip="Read Me" matTooltipPosition="right"
                            *ngIf="showInfoIcon" matTooltipClass="cisco-tooltip">
                            <a class="infoColor" (click)="onClickInfoIcon('open','Valid_Domain_Info')">
                                <span class="icon-info-outline icon-small"></span>
                            </a>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body" style="text-align: initial;">
                <div class="row">
                    <div class="col-12 line-broken-msg">
                        {{message}}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row" style="display: flex !important;">
                    <div class="col-8"></div>
                    <div class="col-4" style="text-align: end;">
                        <button mat-raised-button class="btn btn-primary" *ngIf="showYesNoBtn === 'No'"
                            (click)="activeModal.close(true)">Ok</button>
                        <button mat-raised-button class="btn btn-dark" *ngIf="showCancelButton && showYesNoBtn === 'No'"
                            (click)="activeModal.close(false)">Cancel</button>
                        <button mat-raised-button class="btn btn-primary" *ngIf="showYesNoBtn === 'Yes'"
                            (click)="activeModal.close(true)">Yes</button>
                        <button mat-raised-button class="btn btn-dark" *ngIf="showYesNoBtn === 'Yes'"
                            (click)="activeModal.close(false)">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-info-details *ngIf="infoPopup" [infoData]="readMeInfo" (clickClose)="onClickInfoIcon($event)"></app-info-details>