<ng-container *ngIf="params.colDef.headerName === 'Job Status'">
    {{params.value}}&nbsp;
    <span *ngIf="params.value === 'Completed'" class="icon-check-outline icon-small"
        style="vertical-align: bottom;"></span>
    <span *ngIf="params.value === 'Failed'" class="icon-presence-end icon-small" style="vertical-align: bottom;"></span>
</ng-container>

<ng-container *ngIf="params.colDef.headerName === 'Source'">
    <a *ngIf="params.value === 'Smart Sheet'" class="grid-renderer-anchor" href="{{params.data.sourceLink}}"
        target="_blank">{{params.value}}</a>
    <ng-template [ngIf]="params.value === 'PDAF'">{{params.value}}
        <ng-template [ngIf]="params.data.sourceLink">({{params.data.sourceLink}})</ng-template>
    </ng-template>
    <ng-template [ngIf]="params.value !== 'PDAF' && params.value !== 'Smart Sheet'">{{params.value}}</ng-template>
</ng-container>