import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'htmlToPlainText'
})
export class HtmlToPlainTextPipe implements PipeTransform {
    public transform(value: any) {
        if (value) {
            value = value.replaceAll('&lt;', '<');
            value = value.replaceAll('&gt;', '>');
            value = value.replaceAll('"&quot;', '"');
            value = value.replaceAll('&quot;"', '"');
            value = value.replaceAll('&#34;', '"');
            value = value.replaceAll('&amp;', '&');
            value = value.replaceAll('&nbsp;', ' ');
        }
        return value;
    }
}
