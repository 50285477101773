import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { SharedService } from 'src/app/shared-service/shared.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
})
export class CallbackComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router,    private sharedService: SharedService,
  ) {}

  ngOnInit() {
    this.authService.state$.subscribe(path => {
      console.log('oauth callback, route to "' + path + '"');
      if (path.length === 0 || path.startsWith('/callback')) {
        path = '';
      }
      console.log('callback path: ' + path);
      // if(path === '/' || path === ''){
      //   this.sharedService.sendClickEvent(true);
      // }else{
      if(decodeURIComponent(path) != '/'){
        setTimeout(() => {        
          this.router.navigate([decodeURIComponent(path)]);
        }, 200);
      }
    //}
    });
  }
}
