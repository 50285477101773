import Quill from 'quill';
import { EmbedBlot } from 'parchment';

class SoftBreak extends EmbedBlot {
    static create(value) {
        let node = super.create(value);
        return node;
    }

    length() {
        return 1;
    }

    value() {
        return '\n';
    }

    static value(node) {
        return '\n';
    }
}

SoftBreak.blotName = 'break';
SoftBreak.tagName = 'BR';
Quill.register(SoftBreak);

var AlignClass: any = Quill.import('attributors/style/align');
Quill.register(AlignClass, true);

export class EditorConstants {
    public static modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ header: 1 }, { header: 2 }],               // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }],          // outdent/indent
            [{ direction: 'rtl' }],                         // text direction

            [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
            [{ size: ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ font: [] }],
            [{ align: [] }],

            ['clean'],                                         // remove formatting button

            ['link']
        ]
    };

    public static questionsModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],

            [{ header: 1 }, { header: 2 }],               // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }],          // outdent/indent
            [{ direction: 'rtl' }],                         // text direction

            [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
            [{ size: ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ font: [] }],
            [{ align: [] }],

            ['clean'],                                         // remove formatting button

            ['link']
        ]
    };
}
