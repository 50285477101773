import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom } from 'rxjs';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from '../loader/loader.service';
import { RouterConstants } from '../constants/router-constants';
import { EncrDecrService } from './encrypt-decrypt.service';
import { environment } from './../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CepmService {
  modalRefBox: any;

  public apiUrl: any;
  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private encDecService: EncrDecrService,
    private modalService: NgbModal,
    private routerConstants: RouterConstants
  ) {
    this.apiUrl = window['__env'].apiUrl;
  }

  callCepmService(): Observable<any> {
    console.log(environment.production)
    return this.http.get(`${this.apiUrl}/cepmService/cepmRolesResources`);
  }

  fetchAllSearchData() {
    return this.http.get(`${this.apiUrl}/dashboardService/fetchAllData`);
  }

  fetchDataBasedOnSearch(requestObj: any) {
    return this.http.post(`${this.apiUrl}/dashboardService/fetchSearchData`, requestObj);
  }

  validateCecId(cecId: any) {
    return this.http.get(`${this.apiUrl}/cepmService/cecidValidation?cecId=${cecId}`);
  }

  async getCepmRole(val: any) {
    this.loaderService.openLoader();
    const respData: any = await lastValueFrom(this.callCepmService())
      .catch((err: any) => {
        this.loaderService.closeLoader();
        this.openConfirmationPopup('Error', err.error.errorMsg, false);
      });
    if (respData && respData.roles && respData.roles.length > 0) {
      if (respData.roles.length === 1 && respData.roles[0].value === 'scw_whitepaper_admin_res') {
      }
      else {
        const userObj = {
          userId: respData.userId,
          employeeCompany: respData.company,
          mcoCompany: respData.mcoCompany,
          fullName: respData.userName,
          employeeNumber: respData.employeeNumber,
          userInitials: respData.userName.match(/\b(\w)/g).join(''),
          role: respData.roles[0],
          email: respData.email,
          environment: respData.environment,
          supplierScope: respData.supplierScope,
          nonRFQScope: respData.nonRFQScope,
          isWhitepaperAdmin: respData.roles.filter((role: any) => role.value === 'scw_whitepaper_admin_res').length > 0 ? 'Y' : 'N',
        };
        this.routerConstants.setLoggedInUser(userObj);
        this.routerConstants.setUserType(userObj.role.value);
        this.routerConstants.setIsPageReloaded(val);
        if (window.location.hostname === 'scworkbench.cisco.com') {
          (window as any).setAppDCustomData('username', userObj.fullName);
          (window as any).setAppDCustomData('user', userObj.userId);
          (window as any).setAppDCustomData('RoleName', userObj.role.name);
          (window as any).setAppDCustomData('Company', userObj.role.value === 'scw_supplier_user_res' ?
            this.routerConstants.getLoggedInUser().mcoCompany : userObj.employeeCompany);
        }

        if (!window.localStorage.getItem('adata') && respData.supplierScope === 'Y') {
          const adata: any = await lastValueFrom(this.validateUser(userObj.role.value === 'scw_supplier_user_res' ?
            this.routerConstants.getLoggedInUser().mcoCompany : 'Admin', userObj.role.value))
            .catch((err: any) => {
              this.loaderService.closeLoader();
              this.openConfirmationPopup('Error', err.error.errorMsg, false);
            });
          if (adata && Object.keys(adata).length > 0) {
            window.localStorage.setItem('adata', this.encDecService.set(adata.token));
            window.localStorage.setItem('rtoken', this.encDecService.set(adata.refreshToken));
          }
        }

        this.loaderService.closeLoader();
      }
    }
    else {
      this.loaderService.closeLoader();
      this.routerConstants.setLoggedInUser({});
      this.routerConstants.setUserType('');
      this.routerConstants.setIsPageReloaded(val);
    }
  }

  validateUser(supplier: any, role: any) {
    return this.http.get(`${this.apiUrl}/cepmService/authenticate?supplier=${encodeURIComponent(supplier)}&role=${role}`);
  }

  logout(refreshToken: any, supplier: any) {
    return this.http.get(`${this.apiUrl}/cepmService/logout?refreshToken=${refreshToken}&supplier=${encodeURIComponent(supplier)}`);
  }

  refreshToken(refreshToken: any) {
    return this.http.get(`${this.apiUrl}/cepmService/refreshToken?refreshToken=${refreshToken}`);
  }

  openCase(requestObj: any, supplierName: any) {
    return this.http.post(`${this.apiUrl}/issueReporterService/openCase?supplierName=${encodeURIComponent(supplierName)}`, requestObj);
  }

  getCaseComments(incidentNumber: any) {
    return this.http.get(`${this.apiUrl}/issueReporterService/viewCaseComments?incidentNumber=number=${incidentNumber}&sysparm_display_value=true&sysparm_fields=comments_and_work_notes`);
  }

  uploadFileToCase(fileReq: any, systemId: any, fileName: any) {
    return this.http.post(`${this.apiUrl}/issueReporterService/uploadFileToCase?fileName=${fileName}&systemId=${systemId}`, fileReq);
  }

  getAllIncidents() {
    return this.http.get(`${this.apiUrl}/issueReporterService/viewReportIssues`);
  }

  getSchedularDetails() {
    return this.http.get(`${this.apiUrl}/commonService/getSchedularDetails`);
  }

  openConfirmationPopup(title: any, msg: any, flag: any) {
    this.modalRefBox = this.modalService.open(ConfirmationComponent, {
      centered: true,
      windowClass: 'alertModalWindowClass',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalRefBox.componentInstance.title = title;
    this.modalRefBox.componentInstance.message = msg;
    this.modalRefBox.componentInstance.showCancelButton = flag;
  }
}
