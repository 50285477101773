<div class="modal fade reportIssuesPopup" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="margin-top: 2% !important;">
            <div class="modal-header" style="display: inline;border-bottom: none;">
                <div class="row">
                    <div class="col-11">
                        <h1 class="modal-title">Report Issue/Provide Feedback</h1>
                    </div>
                    <div class="col-1">
                        <button mat-icon-button style="float: right;" (click)="onClose('close')">
                            <span class="icon-close icon-small"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body" style="text-align: initial;"
                [style.height]="selectedReportTabIndex === 0 ? '65vh !important' : '80vh !important'">
                <div class="row" style="border-bottom: 1px solid rgba(0,0,0,.12);">
                    <div class="col-8">
                        <nav mat-tab-nav-bar>
                            <ng-container *ngFor="let tab of reportTabs; let tabIndex = index">
                                <a mat-tab-link (click)="onChangeSelectedTab(tabIndex)"
                                    [active]="tabIndex === selectedReportTabIndex">{{tab}}
                                </a>
                            </ng-container>
                        </nav>
                    </div>
                </div>
                <ng-template [ngIf]="selectedReportTabIndex === 0">
                    <form [formGroup]="issueForm">
                        <div class="row" style="display: inherit;text-align: center;">
                            <label style="font-weight: bold;">Enter <em>all required</em>
                                information.
                                <em>Fields</em>
                                in which you must enter data are marked with Asterisk<span style="color: red;">
                                    *</span></label>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <mat-label style="padding-left: 0.5%;">Category<span style="color:red">*</span>
                                </mat-label>
                                <mat-radio-group formControlName="category" aria-label="Select an option"
                                    (change)="onSelectCategory($event)">
                                    <mat-radio-button *ngFor="let cat of categories" value="{{cat}}">{{cat}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="col-12" style="margin-top: 1%;">
                                <mat-form-field class="issuesFields" appearance="outline" *ngIf="showDataMeasureName">
                                    <mat-label>Data Measure Name </mat-label>
                                    <mat-select formControlName="dataMeasureName">
                                        <mat-option *ngFor="let d of dataMeasureArr" [value]="d.description">
                                            {{d.description}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="issuesFields" appearance="outline" *ngIf="showBrokenLink"
                                    style="width: 99%;">
                                    <mat-label>Broken Link / Issue (Copy/Paste)
                                    </mat-label>
                                    <input matInput formControlName="brokenLinkName">
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="issuesFields description" appearance="outline">
                                    <mat-label>Description Of Issue </mat-label>
                                    <textarea matInput formControlName="additionalComments"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <div class="row fileSection" appDnd (fileDropped)="onFileDropped($event)">
                                    <div class="col-12" style="text-align: center;margin-top: 0.5%;">
                                        <span><strong>Note : </strong><span style="color: red;">Only
                                                {{supportedFileExtensions}}
                                                Documents are
                                                allowed
                                                to be
                                                uploaded</span></span><br>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                            viewBox="0 0 63 64">
                                            <g fill="#017cad" fill-rule="nonzero">
                                                <path
                                                    d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
                                                <path
                                                    d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
                                                <path
                                                    d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="col-12"
                                        style="text-align: center;margin-top: 0.5%;margin-bottom: 0.5%;font-weight: bold;">
                                        <span>Drag & Drop File Here</span><br>
                                        <span>OR</span>
                                    </div>
                                    <div class="col-12" style="text-align: center;margin-bottom: 0.5%;">
                                        <input #browseFile type="file" (click)="browseFile.value = null"
                                            (change)="uploadFile($event.target.files)" style="display:none">
                                        <input type="hidden">
                                        <button mat-raised-button class="btn btn-secondary" (click)="browseFile.click()"
                                            style="float: initial !important;">Browse
                                            Document</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="isInvalidFileFormat">
                                <span *ngIf="isInvalidFileFormat" style="color: red;">Only
                                    {{supportedFileExtensions}} type file is allowed.</span>
                            </div>
                            <ng-container *ngFor="let file of selectedFiles">
                                <div class="row fileSection" *ngIf="selectedFiles.length > 0">
                                    <div class="col-1">
                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                            viewBox="0 0 58 58"
                                            style="enable-background:new 0 0 58 58;height: 37px !important;"
                                            xml:space="preserve">
                                            <polygon style="fill:#EDEADA;"
                                                points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                                            <g>
                                                <path style="fill:#CEC9AE;"
                                                    d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                                                <path style="fill:#CEC9AE;"
                                                    d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                                                <path style="fill:#CEC9AE;"
                                                    d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                                                <path style="fill:#CEC9AE;"
                                                    d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                                                <path style="fill:#CEC9AE;"
                                                    d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                                            </g>
                                            <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
                                        </svg>
                                    </div>
                                    <div class="col-10">
                                        <div class="col-12">
                                            <h4 class="fileName">
                                                {{getFileName(file.name)}}
                                            </h4>
                                        </div>
                                        <div class="col-12">
                                            <p class="fileSize">
                                                {{formatBytes(file.size)}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <span class="icon-delete" matTooltip="Remove File"
                                            matTooltipClass="cisco-tooltip" matTooltipPosition="right"
                                            (click)="onClickDeleteFile()">
                                        </span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="row" style="text-align: left;">
                            <quill-view-html [content]="btnText"></quill-view-html>
                        </div>
                    </form>
                </ng-template>
                <ng-template [ngIf]="selectedReportTabIndex === 1">
                    <ag-grid-angular #myIssueGrid id="myIssueGrid" class="ag-theme-balham"
                        style="height: 68vh !important;" [rowData]="myIssuesList.rowData"
                        [columnDefs]="myIssuesList.columnDefs" [components]="frameworkComponents"
                        [defaultColDef]="defaultColDef" [animateRows]="true" [suppressDragLeaveHidesColumns]="true"
                         [paginationAutoPageSize]="true" [pagination]="true"
                        [context]="context" [gridOptions]="gridOptions" [columnTypes]="columnTypes">
                    </ag-grid-angular>
                </ng-template>
                <ng-template [ngIf]="selectedReportTabIndex === 2">
                    <ag-grid-angular #allIssueGrid id="allIssueGrid" class="ag-theme-balham"
                        style="height: 68vh !important;" [columnDefs]="allIssueList.columnDefs"
                        [components]="frameworkComponents" [defaultColDef]="defaultColDef" [animateRows]="true"
                        [suppressDragLeaveHidesColumns]="true" 
                        [rowData]="allIssueList.rowData" [paginationAutoPageSize]="true" [pagination]="true"
                        [context]="context" [gridOptions]="gridOptions" [columnTypes]="columnTypes">
                    </ag-grid-angular>
                </ng-template>
            </div>
            <div class="modal-footer" *ngIf="selectedReportTabIndex === 0">
                <div class="row" style="display: flex !important;">
                    <div class="col-8"></div>
                    <div class="col-4" style="text-align: end;">
                        <button mat-raised-button class="btn btn-primary" [disabled]="issueForm.invalid"
                            (click)="onClickSubmit()">Submit</button>
                        <button mat-raised-button class="btn btn-dark" (click)="issueForm.reset()">Reset</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade worknotesPopup" style="display: block;" *ngIf="showIncident">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="margin-top: 2% !important;">
            <div class="modal-header" style="display: inline;border-bottom: none;">
                <div class="row" style="border-bottom: 1px solid #dee2e6;">
                    <div class="col-11">
                        <h1 class="modal-title">{{incidentId}}</h1>
                    </div>
                    <div class="col-1">
                        <button mat-icon-button style="float: right;" (click)="onCloseIncident()">
                            <span class="icon-close icon-small"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body" style="text-align: initial;" [style.height]="'70.5vh !important'">
                <div class="row">
                    <ng-container *ngFor="let comm of incidentComments">
                        <div class="col-12"
                            [ngClass]="comm.commentType === 'additional comment'?'additional-comments':'work-notes'">
                            <label class="commented-by">{{comm.commentedBy}}</label>
                            <label class="comment-time">
                                <ng-template [ngIf]="comm.commentType === 'additional comment'">
                                    Additional comments
                                </ng-template>
                                <ng-template [ngIf]="comm.commentType === 'work notes'">
                                    Work Notes
                                </ng-template>
                                &nbsp;&nbsp;{{comm.commentTime}}
                            </label>
                            <label class="comment-description">{{comm.comment}}</label>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row" style="display: flex !important;">
                    <div class="col-10"></div>
                    <div class="col-2" style="text-align: end;">
                        <button mat-raised-button class="btn btn-dark" (click)="onCloseIncident()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>