import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RouterConstants } from 'src/app/constants/router-constants';

@Component({
  selector: 'app-report-renderer',
  templateUrl: './report-renderer.component.html',
  styleUrls: ['./report-renderer.component.css']
})
export class ReportRendererComponent implements ICellRendererAngularComp {
  espUrl: any;
  params: any;
  count: any;
  loggedInUser: any;

  constructor(
    private routerConstants: RouterConstants
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.loggedInUser = this.routerConstants.getLoggedInUser();
  }

  refresh(): boolean {
    return true;
  }

  onSelectIncidentId() {
    this.params.context.componentParent.onSelectIncidentId(this.params.data);
  }

  redirectToSummaryLink(summaryLink: any) {
    window.open(summaryLink, '_blank');
  }

  redirectToEsp(incidentId) {
    this.espUrl = this.loggedInUser.environment === 'prod' ? `https://cisco.service-now.com/nav_to.do?uri=%2Fincident.do%3Fsysparm_query%3Dnumber%3D${incidentId}` :
      `https://ciscodev.service-now.com/nav_to.do?uri=%2Fincident.do%3Fsysparm_query%3Dnumber%3D${incidentId}`;
    window.open(this.espUrl, '_blank');
  }
}
