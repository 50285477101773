import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderComponent } from './loader.component';

@Injectable()
export class LoaderService {

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<LoaderComponent>
    ) { }

    openLoader(): void {
        this.dialogRef = this.dialog.open(
            LoaderComponent,
            {
                panelClass: 'custom-loaderClass',
                disableClose: true
            });
    }

    closeLoader(): void {
        return this.dialogRef.close();
    }
}
