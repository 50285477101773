import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DateFilterService {
    constructor(private datePipe: DatePipe) {

    }

    comparator(filterLocalDateAtMidnight, cellValue) {
        const searchedDate = new Date(
            this.datePipe.transform(new Date(filterLocalDateAtMidnight).setHours(0, 0, 0, 0), 'MM/dd/yyyy HH:mm:ss')
        );

        if (!cellValue) {
            return 0;
        }

        const cellDate = new Date(
            this.datePipe.transform(new Date(cellValue).setHours(0, 0, 0, 0), 'MM/dd/yyyy HH:mm:ss')
        );

        if (cellDate < searchedDate) {
            return -1;
        }
        else if (cellDate > searchedDate) {
            return 1;
        }
        return 0;
    }

    dateComparatorSort(date1: any, date2: any) {
        return +new Date(date1) - +new Date(date2);
    }
}