import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class LookupService {

    public apiUrl: any;
    constructor(private http: HttpClient) {
        this.apiUrl = window['__env'].apiUrl;
    }

    getDropdownLookup(lookupType: any, viewType?: any,surveyId?:any) {
        viewType = viewType ? viewType : null;
        surveyId = surveyId ? surveyId : null;
        return this.http.get(`${this.apiUrl}/lookupService/getDropdownLookup?viewType=${viewType}&lookupValue=${lookupType}&surveyId=${surveyId}`);
    }

    viewMasterLookup() {
        return this.http.get(`${this.apiUrl}/lookupService/viewMasterLookup`);
    }

    saveOrUpdateLookupType(requestObj: any) {
        return this.http.post(`${this.apiUrl}/lookupService/createUpdateMasterLookup`, requestObj);
    }

    viewChildLookup(selectedLookupType: any) {
        return this.http.get(`${this.apiUrl}/lookupService/viewChildLookup?masterLookupId=${selectedLookupType.masterLookupId}`);
    }

    saveOrUpdateLookupValue(requestObj: any) {
        return this.http.post(`${this.apiUrl}/lookupService/createUpdateChildLookup`, requestObj);
    }

    multiUpdateChildLookup(requestObj: any) {
        return this.http.post(`${this.apiUrl}/lookupService/multiUpdateChildLookup`, requestObj);
    }
}
