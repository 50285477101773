import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { SearchPipe } from './search.pipe';
import { HtmlToPlainTextPipe } from './htmlToPlainText.pipe';

@NgModule({
    declarations: [
        SearchPipe,
        HtmlToPlainTextPipe
    ],
    imports: [
        CommonModule
    ],
    providers: [
        DatePipe,
        DecimalPipe
    ],
    exports: [
        SearchPipe,
        HtmlToPlainTextPipe
    ]
})

export class PipesModule { }
