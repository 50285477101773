import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ToolsService {

    public apiUrl:any;
    constructor(private http: HttpClient) {
        this.apiUrl = window['__env'].apiUrl;
    }

    getToolsDetails(supplierName: any) {
        return this.http.get(`${this.apiUrl}/toolsService/getGsmToolsDetails?supplierName=${encodeURIComponent(supplierName)}`);
    }

    saveOrUpdateToolsDetails(typeOfOperation, requestObj: any) {
        return this.http.post(`${this.apiUrl}/toolsService/createGsmTool?typeOfOperation=${typeOfOperation}`, requestObj);
    }

    downloadAttachments(toolId: any, fileName: any) {
        return this.http.get(`${this.apiUrl}/docNTrainingService/downloadFile?id=${toolId}&fileName=${encodeURIComponent(fileName)}&flag=T`, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }
}
