import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    public transform(values: any, fieldValue: string, searchedText: string, id?: any) {
        if (!searchedText) {
            return false;
        }
        else {
            const isMatched = new RegExp(searchedText, 'gi').test(fieldValue) ? false : true;
            if (id && isMatched) {
                const scroll = document.getElementById(`${id}`);
                if (scroll) {
                    setTimeout(() => {
                        scroll.scrollIntoView();
                    }, 5);
                }
            }
            return isMatched;
        }
    }
}
