<div class="modal fade timeoutPopup" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="margin-top: 2% !important;">
            <div class="modal-header">
                <div class="row">
                    <div class="col-12">
                        <h1 class="modal-title">Warning !!!</h1>
                    </div>
                </div>
            </div>
            <div class="modal-body" style="text-align: initial;">
                <div class="row">
                    <div class="col-12">
                        {{message}}
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="row" style="display: flex !important;">
                    <div class="col-8"></div>
                    <div class="col-4" style="text-align: end;">
                        <button mat-raised-button class="btn btn-primary"
                            (click)="activeModal.close('No longer idle.')">Stay</button>
                        <button mat-raised-button class="btn btn-danger"
                            (click)="activeModal.close('Timed out!')">Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>