import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    public apiUrl: any;
    constructor(private http: HttpClient) {
        this.apiUrl = window['__env'].apiUrl;
    }

    getDashboardDetails(supplier: any) {
        return this.http.get(`${this.apiUrl}/dashboardService/getDashboardDetails?viewType=${encodeURIComponent(supplier)}`);
    }

    getToolTipsDetails(userRole: any) {
        return this.http.get(`${this.apiUrl}/toolTipService/getToolTipsDetails?userRole=${userRole}`);
    }

    createToolTip(requestObj: any, typeOfOperation: any) {
        return this.http.post(`${this.apiUrl}/toolTipService/createToolTips?typeOfOperation=${typeOfOperation}`, requestObj);
    }

    createOrUpdateDashboard(requestObj: any) {
        return this.http.post(`${this.apiUrl}/dashboardService/createUpdateSupplierDashboard`, requestObj);
    }

    deleteDashboard(dashboardId: any) {
        return this.http.get(`${this.apiUrl}/dashboardService/deleteDashboard?dashboardId=${dashboardId}`);
    }
}
