import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-data-refresh-renderer',
  templateUrl: './data-refresh-renderer.component.html',
  styleUrls: ['./data-refresh-renderer.component.css']
})
export class DataRefreshRendererComponent implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
